<template>
  <!-- <v-col v-if="_.isEmpty(epot)">
      <v-spacer class="mt-16">
        <p class="text-center">
          <img src="@/assets/image/data_empty.png" />
        </p>
        <h3 class="text-center font-weight-medium">
          Tidak ada Test
        </h3>
        <p class="text-center">
          <v-btn
            class=" white--text"
            elevation="0"
            color="primary"
            style="text-transform: capitalize"
            to="/exam/create"
          >
            <v-icon>mdi-plus</v-icon>
            new test
          </v-btn>
        </p>
      </v-spacer>
    </v-col> -->
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <v-row>
      <v-col class="d-flex" md="6">
        <v-tabs
          v-model="tabs"
          class="text-capitalize my-auto badged-tabs"
          color="primary"
          show-arrows="mobile"
        >
          <v-tab class="text-capitalize">
            <v-badge :content="String(all.total || '0')" inline>
              Semua Test
            </v-badge>
          </v-tab>
          <v-tab class="text-capitalize">
            <v-badge :content="String(publishes.total || '0')" inline>
              Published
            </v-badge>
          </v-tab>
          <v-tab class="text-capitalize">
            <v-badge :content="String(drafts.total || '0')" inline>
              Draft
            </v-badge>
          </v-tab>
          <v-tab class="text-capitalize">
            <v-badge :content="String(archives.total || '0')" inline>
              Archive
            </v-badge>
          </v-tab>
        </v-tabs>
        <!-- <template v-slot:extension> -->
        <!-- </template> -->
      </v-col>
      <v-col cols="6" md="6" class="text-right">
        <div class="d-flex float-right">
          <v-text-field
            label="Cari kode"
            hide-details
            class="pt-1 mr-3 -soft mx-width-85 search-field"
            v-model="filter.code"
            @keydown.enter="fetchEpot"
            ><v-icon
              slot="append"
              class="-soft rounded-circle"
              @click="fetchEpot"
              v-ripple="{ center: true }"
              v-text="'$search'"
            ></v-icon
          ></v-text-field>

          <v-btn
            class="ml-2 mr-2 white--text text-capitalize"
            elevation="0"
            color="color"
            icon
            @click="$set(filter, 'dialog', true)"
          >
            <v-icon> ri-filter-line </v-icon>
          </v-btn>

          <v-btn
            class="ml-2 mr-2 white--text text-capitalize"
            elevation="0"
            color="primary"
            to="/exam/create"
          >
            <v-icon>mdi-plus</v-icon>
            new test
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-tabs-items id="tabsItem" v-model="tabs" style="background: transparent">
      <v-tab-item>
        <v-row>
          <v-col>
            <epot-table
              :loading="table_loading"
              :showSelect="false"
              :items="all"
              @refresh-epot="fetchEpot"
              @refresh-list="fetchEpotAll"
              @delete="deleting"
              @update="update"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <template v-if="selected.publishes.length">
              <v-btn
                color="primary"
                elevation="0"
                @click="update(selected.publishes, 'archive')"
                >Archive</v-btn
              >
            </template>
            <epot-table
              :loading="table_loading"
              :items="publishes"
              :selected="selected.publishes"
              @refresh-epot="fetchEpot"
              @refresh-list="fetchPublish"
              @update="update"
              @delete="deleting"
              @select="selected.publishes = $event"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <template v-if="selected.drafts.length">
              <!-- <v-btn v-if="isCheckAll" color="primary">Archive</v-btn>  -->
              <v-btn
                elevation="0"
                color="primary"
                @click="update(selected.drafts, 'published')"
                >Publish</v-btn
              >
            </template>
            <epot-table
              :loading="table_loading"
              :items="drafts"
              :selected="selected.drafts"
              @refresh-epot="fetchEpot"
              @refresh-list="fetchDraft"
              @update="update"
              @delete="deleting"
              @select="selected.drafts = $event"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <template v-if="selected.archives.length">
              <v-btn
                color="primary"
                elevation="0"
                @click="update(selected.archives, 'published')"
                >Publish</v-btn
              >
            </template>
            <epot-table
              :loading="table_loading"
              :items="archives"
              :selected="selected.archives"
              @refresh-epot="fetchEpot"
              @refresh-list="fetchArchive"
              @update="update"
              @delete="deleting"
              @select="selected.archives = $event"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <!-- Update dialog -->
    <v-dialog v-model="is_updating" max-width="600px">
      <v-card>
        <v-card-title>
          <span> <v-icon>$warning</v-icon> Update status </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <strong
              v-if="new_status == 'published' && old_status == 'draft'"
              class="d-block"
            >
              <v-icon color="warning">$warning</v-icon>
              Pertanyaan yang telah di Publish tidak dapat diubah kembali
            </strong>
            Apakah anda ingin mengubah status paket epot ini dari
            <strong :style="{ textTransform: 'capitalize ' }"
              >"{{ old_status }}"</strong
            >
            menjadi
            <strong :style="{ textTransform: 'capitalize ' }"
              >"{{ new_status }}"</strong
            >?
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" small elevation="0" @click="cancel()">
            Batal
          </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            small
            elevation="0"
            @click="updating()"
          >
            Ya. Ubah!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog v-model="is_deleting" max-width="600px">
      <v-card>
        <v-card-title class="px-4">
          <span> <v-icon>$warning</v-icon> Hapus paket </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            Apakah anda yakin ingin menghapus paket <strong>{{ temp_exam.code }}</strong> ?
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" small elevation="0" @click="cancelDelete()">
            Batal
          </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            small
            elevation="0"
            @click="confirmDelete()"
          >
            Ya. Hapus!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filter.dialog" max-width="600px">
      <v-card>
        <v-card-title>Filter lanjutan</v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <!-- <v-row>
            <v-col
              cols="12"
              sm="4"
              class="mb-2 pb-sm-3 pb-0 text-left text-sm-right"
              align-self="center"
            >
              <div>Kode paket</div>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="filter.code"
                filled
                outlined
                hide-details="auto"
              />
            </v-col>
          </v-row> -->

          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="mb-2 pb-sm-3 pb-0 text-left text-sm-right"
              align-self="center"
            >
              <div>Section</div>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select
                filled
                v-model="filter.section"
                :items="['All', 'listening', 'reading', 'structure']"
                outlined
                hide-details="auto"
              >
                <template slot="item" slot-scope="{ item }">
                  <div class="text-capitalize">{{ item }}</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="mb-2 pb-sm-3 pb-0 text-left text-sm-right"
              align-self="center"
            >
              <div>Part</div>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select
                filled
                v-model="filter.part"
                :items="['All', 'A', 'B', 'C']"
                outlined
                hide-details="auto"
              >
                <template slot="item" slot-scope="{ item }">
                  <div class="text-capitalize">{{ item }}</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <div class="text-right mt-2">
            <v-btn
              elevation="0"
              small
              color="primary"
              class="mr-2"
              @click="search"
              >Terapkan</v-btn
            >
            <v-btn
              small
              outlined
              color="primary"
              class="mr-2"
              @click="cancelSearch"
              >Hapus filter</v-btn
            >
            <v-btn
              small
              outlined
              color="primary"
              @click="$delete(filter, 'dialog')"
              >Batal</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar top v-model="snackbar.state">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import EpotTable from "./components/EpotTable";
export default {
  name: "epot-list",
  components: { EpotTable },
  data() {
    return {
      is_updating: false,
      is_deleting: false,
      table_loading: false,
      tabs: null,
      all: {},
      archives: {},
      drafts: {},
      publishes: {},
      selected: {
        publishes: [],
        drafts: [],
        archives: [],
      },

      new_items: [],
      new_status: "",
      temp_exam: {},

      snackbar: {
        state: false,
        text: "",
      },

      filter: {
        code: "",
        section: "All",
        part: "All",
      },
    };
  },
  computed: {
    old_status() {
      if (Array.isArray(this.new_items) && this.new_items.length) {
        return this.getStatusLabel(this.new_items[0].status);
      } else if (this.new_items.status) {
        return this.getStatusLabel(this.new_items.status);
      }
      return "";
    },
    computed_filter() {
      let filter = {};

      if (this.filter.code) {
        filter.code = this.filter.code;
      }
      if (this.filter.part) {
        switch (this.filter.part.toLowerCase()) {
          case "no part":
            filter.part = "";
            break;
          case "all":
            break;
          default:
            filter.part = this.filter.part;
            break;
        }
      }
      if (this.filter.section && this.filter.section.toLowerCase() != "all") {
        filter.section = this.filter.section;
      }
      return filter;
    },
  },
  created() {
    this.$emit("page-changed", 0, { title: "Tes EPOT", link: "/exam" });
    this.fetchEpot();
  },
  methods: {
    // FORNEXT: gak usah pake async, tapi pake recursive aja (cc di epot table juga)
    update(item, status) {
      this.is_updating = true;
      this.new_items = item;
      this.new_status = status;
    },
    search() {
      this.$delete(this.filter, "dialog");
      this.fetchEpot();
    },
    cancelSearch() {
      this.filter = {};
      this.fetchEpot();
    },
    getStatusLabel(status) {
      if (status == 2) return "published";
      if (status == 1) return "draft";
      if (status == 3) return "archive";
      return "😕";
    },
    async updating() {
      let item = this.new_items;
      let status = this.getStatusCode(this.new_status);
      if (!Array.isArray(item)) item = [item];

      let arr = item;
      let epot = {};

      for (let i = 0; i < arr.length; i++) {
        epot = arr[i];
        await this.$store
          .dispatch("exam/update", { ...epot, status })
          .then(() => {
            this.snackbar.text = `Status ${epot.code} berhasil diubah`;
            this.snackbar.state = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }

      this.$set(this.selected, 'publishes', [])
      this.$set(this.selected, 'drafts', [])
      this.$set(this.selected, 'archives', [])
      this.fetchEpot();
      this.cancel();
    },
    async confirmDelete() {
      const code = this.temp_exam.code.valueOf();
      await this.$store.dispatch("exam/delete", this.temp_exam.id)
        .then(() => {
          this.snackbar.text = `Paket ${code} berhasil dihapus`;
          this.snackbar.state = true;
        })
        .catch((error) => {
          this.snackbar.text = error;
          this.snackbar.state = true;
          console.error(error);
        });

      this.fetchEpot();
      this.cancelDelete();
    },
    deleting(exam) {
      this.temp_exam = exam;
      this.is_deleting = true;
    },
    cancel() {
      this.new_items = [];
      this.new_status = "";
      this.is_updating = false;
    },
    cancelDelete() {
      this.temp_exam = {};
      this.is_deleting = false;
    },
    getStatusCode(status) {
      if (status == "published") return 2;
      if (status == "draft") return 1;
      if (status == "archive") return 3;
    },
    async fetchEpot() {
      await this.fetchEpotAll();
      await this.fetchPublish();
      await this.fetchDraft();
      await this.fetchArchive();
    },
    async fetchEpotAll(sort = "", dir = "asc") {
      this.table_loading = true;
      this.$set(this.all, "list", []);
      await this.$store
        .dispatch("exam/list", {
          page: this.all.page || 1,
          limit: this.all.limit || 10,
          sort,
          dir,
          ...this.computed_filter,
        })
        .then((res) => {
          this.all = res.data;
        });
      this.table_loading = false;
    },
    async fetchPublish(sort = "", dir = "asc") {
      this.table_loading = true;
      this.$set(this.publishes, "list", []);
      await this.$store
        .dispatch("exam/list", {
          status: "published",
          page: this.publishes.page || 1,
          limit: this.publishes.limit || 10,
          sort,
          dir,
          ...this.computed_filter,
        })
        .then((res) => {
          this.publishes = res.data;
        });
      this.table_loading = false;
    },
    async fetchDraft(sort = "", dir = "asc") {
      this.table_loading = true;
      this.$set(this.drafts, "list", []);
      await this.$store
        .dispatch("exam/list", {
          status: "draft",
          page: this.drafts.page || 1,
          limit: this.drafts.limit || 10,
          sort,
          dir,
          ...this.computed_filter,
        })
        .then((res) => {
          this.drafts = res.data;
        });
      this.table_loading = false;
    },
    async fetchArchive(sort = "", dir = "asc") {
      this.table_loading = true;
      this.$set(this.archives, "list", []);
      await this.$store
        .dispatch("exam/list", {
          status: "archive",
          page: this.archives.page || 1,
          limit: this.archives.limit || 10,
          sort,
          dir,
          ...this.computed_filter,
        })
        .then((res) => {
          this.archives = res.data;
        });
      this.table_loading = false;
    },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 80px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  color: #9cafc6 !important;
}
.theme--light.v-data-table th,
.theme--light.v-data-table .v-data-footer {
  border: 0px !important;
}
.max-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.v-data-footer__select {
  justify-content: right !important;
  position: relative !important;
  left: 0;
}
.search-field.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-bottom: 0px solid transparent !important;
}
.search-field.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: inherit;
}
.mx-width-85 {
  max-width: 114px !important;
}
.theme--light.v-label {
  color: #9cafc6 !important;
}
#tabsItem .v-simple-checkbox {
  font-weight: 100;
}
#tabsItem .ri-checkbox-blank-line:before {
  content: "\eb7f";
  color: #9cafc6 !important;
}
</style>
