<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <!-- NOTIFICATION -->
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="600px">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 text-first d-flex mb-4" style="background-color: #00549a;height:50px">
          <span class="white--text">
            Dialog Filter
          </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.filtered = false" color="white">ri-close-line</v-icon>
        </v-card-title>
        <v-card-text style="height: 350px;" class="py-5">
          <v-row>
            <v-col cols="4">
              <div class="mt-3">
                Asal Daerah
              </div>
            </v-col>
            <v-col cols="8">
              <v-text-field
                dense
                outlined
                color="#00549a"
                label="Filter Asal Daerah"
                v-model="form.filter_place">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="100"
            color="#00549a"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="initialize()"
          >
            Filter
          </v-btn>
          <v-btn
            width="100"
            elevation="0"
            color="yellow darken-2"
            class="text-capitalize white--text"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="process.run == false || searching != ''">
      <v-col cols="12" md="8" v-if="process.run == false || searching != ''">
        <div class="d-flex" style="width: 500px">
          <v-text-field
            v-model="searching"
            class="mr-3"
            outlined
            autocomplete="off"
            dense
            v-on:keyup.enter="initialize(searching,'')"
            prepend-inner-icon="ri-search-line"
            placeholder="Cari berdasarkan Kode Lisensi . . ."
            hide-details="auto">
            <template v-slot:append v-if="searching">
              <v-icon @click="searching = '';initialize()">ri-close-line</v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>
      <!-- <v-col cols="12" md="4" class="text-end">
        <v-btn
          color="#00549a"
          depressed
          v-if="process.run == false && listData.total > 0"
          dense
          @click="resetForm(undefined, true)"
          class="white--text text-capitalize">
          Add New Lembaga
        </v-btn>
      </v-col> -->
    </v-row>

    <!-- <div class="text-center mt-7" v-if="process.run == false && listData.total < 1">
      <div class="mt-5">
        <v-img :src="require('@/assets/image/data_empty.png')" width="200" class="mx-auto"></v-img>
      </div>
      <div class="mt-3">Please add new Lembaga</div>
      <div class="mt-2">by click the button below</div>
      <v-btn
        color="#00549a"
        depressed
        dense
        @click="resetForm(undefined, true)"
        class="mt-3 white--text text-capitalize">
        Add New Lembaga
      </v-btn>
    </div> -->

    <div v-if="process.run" class="mt-3 mb-8">
      <v-skeleton-loader
        class="ma-auto"
        :loading="process.run"
        :tile="false"
        transition="scale-transition"
        type="table">
      </v-skeleton-loader>
    </div>

    <!-- {{ listData }} -->

    <!-- # List Table -->
    <v-row v-if="process.run == false && listData.list.length > 0">
      <v-col cols="12">
        <v-card class="mb-10">
          <v-data-table
            show-select
            v-model="institusion_ids"
            item-key="id"
            :loading="process.run"
            disable-sort
            :headers="headers"
            :items="process.run ? [] : listData.list"
            :items-per-page.sync="listData.limit"
            :page.sync="listData.page"
            :mobile-breakpoint="0"
            :server-items-length="listData.total"
            loading-text="Loading... Please wait"
            hide-default-footer>
            <template slot="footer" slot-scope="{ props }">
              <v-row class="px-4">
                <div class="pa-3">
                  <v-row align="center">
                    <div>
                      <v-subheader
                        v-text="'Baris Per Halaman: '"
                      ></v-subheader>
                    </div>
                    <div>
                      <v-select
                        class="mt-0 pt-0"
                        style="width: 4rem"
                        v-model="listData.limit"
                        dense
                        :items="[10, 20, 50, 100]"
                        hide-details="auto"
                      >
                      </v-select>
                    </div>
                  </v-row>
                </div>
                <v-col>
                  <v-row class="mx-3" justify="end" align="center">
                    <div class="d-flex flex-nowrap align-center">
                      <v-subheader class="mr-2">Halaman:</v-subheader>
                      <v-select
                        v-model="listData.page"
                        dense
                        class="pa-0 ma-0 mr-2"
                        hide-details
                        :items="
                          Array.from(
                            {
                              length: Math.ceil(
                                listData.total / listData.limit
                              ),
                            },
                            (_, i) => i + 1
                          )
                        "
                        style="width: 5rem; text-align: center !important"
                      >
                      </v-select>
                      <v-subheader>
                        Menampilkan data ke
                        {{ props.pagination.pageStart + 1 }} sampai
                        {{ props.pagination.pageStop }} dari
                        {{ props.pagination.itemsLength }}
                      </v-subheader>
                    </div>
                    <v-btn
                      icon
                      small
                      :disabled="listData.page == 1"
                      @click="listData.page--"
                    >
                      <v-icon>$prev</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      :disabled="
                        listData.page ==
                        Math.ceil(listData.total / listData.limit)
                      "
                      @click="listData.page++"
                    >
                      <v-icon>$next</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <template slot="item.start_test" slot-scope="{ item }">
              <span class="text-center">
                {{ item.end_test | datetime }}
              </span>
            </template>
            <template slot="item.member.fullname" slot-scope="{ item }">
              <span class="text-no-wrap">
                {{ item.member.fullname }}
              </span>
            </template>
            <template slot="item.date_of_birth" slot-scope="{ item }">
              <span class="text-center">
                {{ item.member.date_of_birth | dateOnly }}
              </span>
            </template>

            <template slot="item.action" slot-scope="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    icon
                    text
                    small
                    :to="`/galery-capture/detail/${item.id}/${item.license}`">
                    <v-icon size="30">ri-eye-fill</v-icon>
                  </v-btn>
                </template>
                <span>Lihat Capture Image</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// import moment from "moment";
// import XLSX from 'xlsx'
export default {
  components: {},
  data() {
    return {
      institusion_ids: [],
      searching: "",
      form: {
        filter_place: "",
        filter_age_min: "",
        filter_age_max: "",
      },
      dialog: {
        filtered: false,
      },
      process: {
        run: false,
      },
      listData: {
        // list: [],
        // limit: 10,
        // page: 1,
        // total: 10,
      },
      headers: [
        { text: "Order ID", align: "center", value: "wordpress.order_id" },
        { text: "Kode Lisensi", align: "center", value: "license" },
        { text: "Email", align: "left", value: "member.email" },
        { text: "Nama", align: "left", value: "member.fullname" },
        { text: "NIM", align: "left", value: "member.meta.nim" },
        { text: "Tanggal Lahir", align: "center", value: "date_of_birth", width: 200 },
        { text: "Tanggal Tes", align: "center", value: "start_test", width: 250 },
        { text: "Aksi", align: "center", value: "action" },
      ],
      // headers: [
      //   { text: "Nama Lembaga", align: "left", value: "name", width: 250 },
      //   { text: "Alamat Lembaga", align: "left", value: "address", width: 250 },
      //   { text: "No. Telphone", align: "left", value: "phone", width: 100 },
      //   { text: "Aksi", align: "end", value: "action", width: 250 },
      // ],
      notification: {
        state: false,
        text: "",
        color: ""
      },
    }
  },
  watch: {
    "listData.limit": function () {
      this.initialize();
    },
    "listData.page": function () {
      this.initialize();
    },
  },
  computed: {
    
  },
  created() {
    this.$emit("page-changed", 0, { title: "Data Detail User", link: "/galery-capture" });
    this.initialize()
  },
  mounted() {

  },
  methods: {
    async initialize(searching){
      this.dialog.filtered = false;
      this.process.run = true;
      // this.$set(this.listData, "list", []);

      this.axios.get("/users/v1/admin/list-sertificate", {
          params: {
            q: searching,
            sort_by: "updated_at",
            sort_type: "desc",
            limit: this.listData.limit,
            page: this.listData.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data);
            // res.data.institusions = res.data.institusions == null ? [] : res.data.institusions;
            this.listData = res.data;
            // this.listData.total = res.data.count;
            this.process.run = false;
          }
        })
        .catch((error) => {
          this.process.run = false;
          let errorData = error.response.data;
          console.log(errorData);
          this.notification = {
            state: true,
            color: "red",
            text: errorData.message
          }
        });
    }
  }
}
</script>