<template>
  <section style="background: #eee">
    <div ref="tesHtml" style="width: fit-content; margin: 16px auto">
      <vue-html2pdf
        v-if="!loading"
        :enable-download="true"
        :preview-modal="false"
        :show-layout="true"
        :pdf-quality="2"
        :filename="`${data.member?.fullname} - Certificate of Achievement`"
        pdf-orientation="landscape"
        :manual-pagination="true"
        pdf-content-width="1122px"
        :paginate-elements-by-height="793"
        pdf-format="a4"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <section
            :style="{
              position: 'relative',
            }"
          >
            <img :src="BG_CERTIFICATE" :style="{ width: '1122px' }" />
            <!-- User data -->
            <div
              :style="{
                position: 'absolute',
                top: '223px',
                left: '500px',
                fontSize: '13px',
                fontWeight: 700,
                textTransform: 'uppercase'
              }"
            >
              {{ data.order_no }}
            </div>
            <!-- User data -->
            <div
              style="
                position: absolute;
                top: 284px;
                left: 310px;
                font-size: 14px;
                line-height: 1.6;
              "
            >
              <div>{{ data.member?.fullname }}</div>
              <div>{{ data.member?.meta?.nim }}</div>
              <div>{{ date_format(data.start_test) }}</div>
            </div>
            <!-- Score data -->
            <div
              style="
                position: absolute;
                top: 409px;
                left: 655px;
                font-size: 14px;
                line-height: 1.6;
              "
            >
              <div>
                {{ data.section ? data.section.reading.total_point : "" }}
              </div>
              <div>
                {{ data.section ? data.section.structure.total_point : "" }}
              </div>
              <div>
                {{ data.section ? data.section.listening.total_point : "" }}
              </div>
              <div>{{ correct }}</div>
              <div>{{ data.score }}</div>
            </div>
            <div
              style="
                position: absolute;
                top: 535px;
                left: 638px;
                font-size: 14px;
                font-weight: 700;
              "
            >
              {{ data.level }}
            </div>
            <div
              style="
                position: absolute;
                top: 645px;
                left: 335px;
                font-size: 12px;
                font-weight: 700;
              "
            >
              {{ expired }}
            </div>
            <div style="position: absolute; top: 560px; left: 185px">
              <vue-qrcode
                :value="`https://admin-eptpnk.epot.co.id/public/certificate/${$route.params.license}`"
                errorCorrectionLevel="H"
                :width="100"
                :margin="0"
                :color="{ dark: '#000' }"
              />
            </div>
          </section>
          <section
            style="padding: 16px; background: white"
            @click="generateFile"
          >
            <div style="width: 90%; margin: 24px">
              <p style="font-size: 22px; font-weight: 700; margin-bottom: 10px">
                Certificate Verification
              </p>
              <div style="width: 65%; margin-bottom: 20px">
                <p
                  style="
                    font-size: 13px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.8);
                    margin-bottom: 20px;
                  "
                >
                  Each certificate has unique QR code. Please scan the QR Code
                  by using QR Code scanner application and click the link
                  appeared after scanning. The link will lead you to a website
                  page that presents the exact information as written on the
                  certificate.If the information on this certificate is not the
                  same as on the website page; especially the name and the
                  score; this certificate is considered as counterfeit or not
                  valid.
                </p>
                <p
                  style="font-size: 24px; font-weight: 700; margin-bottom: 10px"
                >
                  Proficiency Level
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 80%;
                    margin-bottom: 10px;
                    color: rgba(0, 0, 0, 0.8);
                  "
                >
                  <tr>
                    <th
                      style="
                        border: 0.5px solid #00000080;
                        text-align: left;
                        padding: 4px;
                      "
                    >
                      Score
                    </th>
                    <th
                      style="
                        border: 0.5px solid #00000080;
                        text-align: left;
                        padding: 4px;
                      "
                    >
                      Proficiency Level
                    </th>
                  </tr>
                  <tr>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      310 - 400
                    </td>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      Beginner
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      403 - 460
                    </td>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      Intermediate
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      463 - 520
                    </td>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      Upper intermediate
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      523 - 677
                    </td>
                    <td style="border: 0.5px solid #00000080; padding: 4px">
                      Advanced
                    </td>
                  </tr>
                </table>
              </div>
              <span
                style="
                  font-size: 13px;
                  font-weight: 400;
                  margin-bottom: 0px;
                  line-height: 1em;
                "
              >
                Issued by Politeknik Negeri Kupang </span
              ><br />
            </div>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </section>
</template>

<script>
import BG_CERTIFICATE from "@/assets/images/certificate.png";
import VueHtml2pdf from "vue-html2pdf";
import VueQrcode from "vue-qrcode";

export default {
  components: {
    VueQrcode,
    VueHtml2pdf,
  },
  data: () => ({
    loading: true,
    data: {},
  }),
  computed: {
    BG_CERTIFICATE: () => BG_CERTIFICATE,
    correct() {
      let section = this.data.section;
      return section
        ? `${
            section.listening.total_point +
            section.reading.total_point +
            section.structure.total_point
          }`
        : "";
    },
    expired() {
      let expired = new Date(this.data.end_test);
      expired.setFullYear(expired.getFullYear() + 2);
      return this.date_format(expired);
    },
  },

  mounted() {
    this.getData();
  },
  methods: {
    date_format(raw_date) {
      let date = new Date(raw_date);

      return Date.parse(raw_date)
        ? `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getDate()}, ${date.getFullYear()}`
        : "";
    },
    async getData() {
      this.loading = true;
      const ACCESS_KEY = process.env.VUE_APP_ACCESS_KEY;
      const { data: res } = await this.axios
        .get(`/license/v1/detail-member-order/${this.$route.params.license}`, {
          headers: {
            "access-key": ACCESS_KEY,
          },
        })
        .catch((e) => {
          this.$router.replace("/404-not-found");
          throw e;
        })
        .finally(() => {});
      this.data = res.data;
      this.loading = false;
      setTimeout(() => {
        this.generateFile();
      }, 5e2);
    },
    generateFile() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style></style>
