<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <!-- NOTIFICATION -->
    <v-snackbar :color="color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- DIALOG DETAIL -->
    <v-dialog v-model="modalDetail" persistent width="500">
      <v-form ref="updateForm" @submit.prevent="update">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Detail Member</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Nama : </span>
                <span>{{member.fullname}}</span>
              </v-col>
              <v-col cols="12">
                <span>Email : </span>
                <span>{{member.email}}</span>
              </v-col>
              <v-col cols="12">
                <span>Tempat lahir : </span>
                <span>{{member.place_of_birth}}</span>
              </v-col>
              <v-col cols="12">
                <span>Tanggal lahir : </span>
                  {{FormatDate(member.date_of_birth)}}
              </v-col>
              <v-col cols="12">
                <span>No Telpon : </span>
                <span>{{member.phone_number}}</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="modalDetail = null;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- DIALOG FORM UPDATE -->
    <v-dialog v-model="modalUpdate" persistent width="500">
      <v-form ref="updateForm" @submit.prevent="update">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Update user</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Nama</span>
                <v-text-field
                  v-model="userUpdate.fullname"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Email</span>
                <v-text-field
                  disabled
                  v-model="userUpdate.email"
                  type="email"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Tempat lahir</span>
                <v-text-field
                  v-model="userUpdate.place_of_birth"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Tanggal lahir
                  <!-- <span class="text--secondary">(Format: YYYY-MM-DD)</span>
                  {{userUpdate.date_of_birth}} -->
                </div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="FormartedDate"
                      append-icon="ri-calendar-line"
                      outlined
                      readonly
                      filled
                      v-bind="attrs"
                      v-on="on"
                      placeholder="DD-MM-YYYY"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    color="primary"
                    :rules="[rules.dateRules]"
                    :max="new Date().toISOString().substr(0, 10)"
                    v-model="userUpdate.date_of_birth"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
                userUpdate = {};
                $refs.updateForm.reset();
                modalUpdate = null;
              "
            >
              Batal
            </v-btn>
            <v-btn type="submit" color="primary" elevation="0" :loading="isUpdate">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-row justify="end">
      <v-col cols="4">
        <v-text-field
          v-model="searching"
          class="mr-3"
          outlined
          dense
          prepend-inner-icon="ri-search-line"
          placeholder="Cari Nama / Email . . ."
          hide-details="auto">
          <template v-slot:append v-if="searching">
            <v-icon @click="searching = ''">ri-close-line</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mb-10">
            <v-data-table
              :loading="process.run"
              disable-sort
              :headers="headers"
              :items="process.run ? [] : listMember.list"
              :items-per-page.sync="listMember.limit"
              :page.sync="listMember.page"
              item-key="id"
              :mobile-breakpoint="0"
              :server-items-length="listMember.total"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader
                          v-text="'Baris Per Halaman: '"
                        ></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="listMember.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="listMember.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(
                                  listMember.total / listMember.limit
                                ),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai
                          {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn
                        icon
                        small
                        :disabled="listMember.page == 1"
                        @click="listMember.page--"
                      >
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="
                          listMember.page ==
                          Math.ceil(listMember.total / listMember.limit)
                        "
                        @click="listMember.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.fullname" slot-scope="{ item }">
                <span class="text-left">
                  {{ item.fullname }}
                </span>
              </template>
              <template slot="item.email" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.email }}
                </span>
              </template>
              <template slot="item.date_of_birth" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.date_of_birth | dateOnly }}
                </span>
              </template>
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="modalDetail = true; member = item;"
                      >
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item)"
                      >
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                </div>
              </template>
              
            </v-data-table>

        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
    modalDetail: null,
    modalUpdate: null,
      process: {
        run: false,
      },
      listMember: {
        limit: 10,
        page: 1,
      },
      headers: [
        { text: "Name", align: "left", value: "fullname" },
        { text: "Email", align: "left", value: "email" },
        { text: "Date of Birth", align: "center", value: "date_of_birth" },
        { text: "Aksi", align: "center", value: "button" },
      ],
      userUpdate: {},
      isUpdate: null,
      data_change: [],
      users: [],
      user: {},
      userQuery: "",
      searching:"",
      id: "",
      member: {},
      userLoading: false,
      notification: {
        state: false,
        text: "",
      },
      menu: null,
      color: "",
    };
  },
  watch: {
    searching: function(newval){
        setTimeout(() => {
            this.initializeMember(newval);
        },800)
    },
    "listMember.limit": function () {
      this.initializeMember();
    },
    "listMember.page": function () {
      this.initializeMember();
    },
    userQuery(val) {
      // Items have already been requested
      this.userLoading = true;

      this.$http
        .get("/users/v1/admin/member", {
          params: {
            q: val,
            page: 1,
            limit: 5,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          this.users = res.data.list;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.userLoading = false));
    },
    user() {
      this.page = 1;
      this.initializeMember();
    },
  },
  computed: {
    FormartedDate() {
      return this.userUpdate.date_of_birth ? moment(this.userUpdate.date_of_birth).format("D MMMM YYYY") : "";
    },
    rules: () => ({
      required: (value) => !!value || "Tidak boleh kosong",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email tidak valid";
      },
      dateRules: (v) => {
        if (!v) return "Tanggal Lahir Tidak Boleh Kosong";
        // const fullDate = v.split("-");
        // const year = fullDate[0];
        // const month = fullDate[1];
        // const day = fullDate[2];

        const fullDate = v.split(" ");
        const day = fullDate[0];
        const month = fullDate[1];
        const year = fullDate[2];

        if (parseInt(year) < 1900) return "Tahun tidak boleh kurang dari 1900";
        if (!parseInt(year) || year.length != 4) return "Format tanggal tidak valid";
        if (!parseInt(month) || month.length != 2) return "Format tanggal tidak valid";
        if (!parseInt(day) || day.length != 2) return "Format tanggal tidak valid";

        if (!Date.parse(`${year}-${month}-${day}`)) {
          return "Format tanggal tidak valid";
        }

        return true;
      },
    }),
  },
  created() {
    this.initializeMember();
  },
  methods: {
    userFilter() {
      return this.users;
    },
    FormatDate(dateOld) {
      
        const filter_data = dateOld ? moment(dateOld).format("DD-MM-YYYY") : "";
        return filter_data;
    },
    initializeMember(searching) {
      this.process.run = true;
      this.$set(this.listMember, "list", []);

      this.$http
        .get("/users/v1/admin/member", {
          params: {
            q: searching,
            limit: this.listMember.limit,
            page: this.listMember.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.listMember = res.data;

          if (!this.listMember.list.length) {
            this.process.run = false;
            return;
          }
          this.listMember.list.forEach(() => {
              total++;
            if (total == this.listMember.list.length) {
                this.process.run = false;
            }
          });
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    updateUser(user) {
      this.modalUpdate = true;
      // console.log(user);
      this.userUpdate = JSON.parse(JSON.stringify(user));
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    update() {
      if (this.$refs.updateForm.validate()) {
        const params = {
          fullname: this.userUpdate.fullname,
          place_of_birth: this.userUpdate.place_of_birth,
          date_of_birth: this.userUpdate.date_of_birth,
          email: this.userUpdate.email,
        };
        const url = `/users/v1/admin/recap/member/update/${this.userUpdate.id}`;

        this.axios
          .put(url, params)
          .then(() => {
            this.notification.state = true;
            this.notification.text = "Data berhasil diubah";
            this.color = "#00549a";
          })
          .catch((error) => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }

            this.notification.state = true;
            this.notification.text = "Error: " + message;
            console.error(error);
          })
          .finally(() => {
            this.initializeMember();
            this.userUpdate = {};
            this.modalUpdate = null;
            this.$refs.updateForm.reset();
          });
      }
    },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 80px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  color: #9cafc6 !important;
}
.theme--light.v-data-table th,
.theme--light.v-data-table .v-data-footer {
  border: 0px !important;
}
.max-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 0;
}
.search-field.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-bottom: 0px solid transparent !important;
}
.search-field.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: inherit;
}
.mx-width-85 {
  max-width: 114px !important;
}
.theme--light.v-label {
  color: #9cafc6 !important;
}
#tabsItem .v-simple-checkbox {
  font-weight: 100;
}
#tabsItem .ri-checkbox-blank-line:before {
  content: "\eb7f";
  color: #9cafc6 !important;
}
</style>
