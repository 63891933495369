<template>
  <div>
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="rounded-lg">
      <v-card-title> Form Import User </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form>
            <!-- IMAGE UPLOAD -->
            <div
              v-if="fileUpload[0] || filepreview"
              class="text-right mb-1 py-0"
            >
              <v-btn color="#d31145" icon depressed small @click="removeFile()">
                <v-icon>ri-delete-bin-6-line</v-icon>
              </v-btn>
            </div>

            <div
              :class="[
                'dropZone',
                dragging ? 'dropZone-over' : 'cursor-pointer',
              ]"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div
                v-if="!fileUpload[0] && filepreview == ''"
                class="dropZone-info"
                @drag="onChange"
              >
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <div class="dropZone-upload-limit-info">
                  <div>Drag or Drop the file here</div>
                  <div>File harus dengan format .xlsx</div>
                  <div class="text-center mt-3">
                    <v-btn width="150" depressed dense> Browse File </v-btn>
                  </div>
                </div>
              </div>
              <div v-else class="dropZone-info">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <div v-if="!process.upload">
                  <v-img
                    aspect-ratio="4"
                    contain
                    class="mx-auto"
                    :src="require('@/assets/image/excel_file.png')"
                  >
                  </v-img>
                  <div class="subtitle-2 font-weight-bold black--text">
                    Nama File : {{ fileUpload[0].name }}
                  </div>
                </div>
                <div v-if="process.upload">
                  <v-progress-linear indeterminate color="primary">
                  </v-progress-linear>
                  <span> Uploading File . . . </span>
                </div>
              </div>
              <form ref="fileUploaded">
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref="inputFile"
                  type="file"
                  @change="onChange"
                />
              </form>
            </div>

            <v-scroll-y-transition>
              <v-alert
                dense
                outlined
                text
                icon="ri-alert-line"
                color="error"
                class="mb-1 mt-5"
                v-show="error.validation.length > 0"
              >
                <div class="subtitle-2" v-html="error.validation"></div>
              </v-alert>
            </v-scroll-y-transition>
            <!-- IMAGE UPLOAD -->

            <v-row class="mt-2">
              <v-col cols="6" class="d-flex align-center">
                <div class="font-weight-medium text-body-1">Send Email</div>
                <!-- <div>Pengaturan sistem maintenance</div> -->
              </v-col>
              <v-col cols="6">
                <v-switch
                  inset
                  class="mt-1 mb-0"
                  v-model="is_send_email"
                  hide-details
                  true-value="1"
                  false-value="0"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pb-2 px-7">
        <v-spacer></v-spacer>
        <v-btn
          width="100"
          elevation="0"
          color="primary"
          class="text-capitalize white--text"
          :loading="process.run"
          :disabled="process.run"
          @click="save()"
        >
          Simpan
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      is_send_email: "0",
      file: [],
      errors: {},
      draging: false,
      /**
       * Attachment
       */
      dragging: false,
      fileUpload: [],
      filepreview: "",
      error: {
        validation: "",
      },
      process: {
        run: false,
        upload: true,
      },
      file_excel: [],
      is_active: false,
      notification: {
        state: false,
        text: "",
        color: "",
      },
    };
  },
  computed: {
    FormartedDate() {
      // format date with moment format
      return moment(this.form.maintenance_date).format("DD MMMM YYYY");
    },
    user() {
      return JSON.parse(localStorage.getItem("ytc_auth"));
    },
  },
  mounted() {},
  methods: {
    removeFile() {
      this.$refs.fileUploaded.reset();
      this.fileUpload = [];
      this.filepreview = "";
      this.file_excel = [];
      this.$refs.inputFile.value = null;
    },
    onChange(e) {
      let src = {
        url: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      };
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0];

      if (this.draging) {
        this.draging = false;
        this.filepreview = URL.createObjectURL(selectedFiles);
        src.url = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
        src.file = selectedFiles;
      } else {
        this.filepreview = URL.createObjectURL(e.target.files[0]);
        src.url = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
        src.file = e.target.files[0];
      }

      src.source = "upload";

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files;
      setTimeout(() => {
        // console.log(this.fileUpload);
        this.process.upload = false;
      }, 2000);
    },
    validation() {
      var error = this.error,
        valid = true;

      if (this.fileUpload.length == 0) {
        error.validation = "File Tidak Boleh Kosong";
        valid = false;
      } else {
        error.validation = "";
      }

      return valid;
    },
    async save() {
      this.process.run = true;
      const isValid = await this.$refs.observer.validate();
      const Valids = await this.validation();
      if (isValid && Valids) {
        let formData = new FormData();
        formData.append("file", this.fileUpload[0]);
        formData.append("is_send_email", this.is_send_email);

        await this.axios
          .post(`/users/v1/admin/import/buyers`, formData)
          .then((response) => {
            let res = response.data;
            if (res.status == 200) {
              this.removeFile();
              this.process.run = false;
              this.notification.state = true;
              this.notification.color = "#4CAF50";
              this.notification.text =
                "Import data Mahasiswa berhasil dilakukan";
            } else {
              this.removeFile();
              this.process.run = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
              let errorData = error.response.data.validation;

              window.scrollTo(0, 0);
              this.process.run = false;
              this.response = "";
              if (errorData != null && Object.keys(errorData).length) {
                Object.keys(errorData).forEach((item) => {
                  this.error.validation += errorData[item] + `</br>`;
                });
              } else {
                this.error.validation = error.response.data.message;
              }
              this.removeFile();
            } else {
              this.error.validation = 'Something went wrong'
              throw error
            }
          });
      } else {
        this.process.run = false;
      }
    },
  },
};
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 400px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #652065;
}

.dropZone:hover .dropZone-title {
  color: #652065;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
