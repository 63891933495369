<template>
  <div>

    <!-- NOTIFICATION -->
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog.preview" max-width="500px" max-height="500px">
      <v-img
        :src="imagePreview"
        :lazy-src="imagePreview"
        class="grey lighten-2">

        <template v-slot:placeholder>

          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center">

            <v-progress-circular
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-dialog>

    <div
      style="
        font-weight: 700;
        font-size: 24px;
        color: #3C4858">
      Data Detail Test - {{ $route.params.lisenci }}
    </div>

    <v-btn
      color="primary"
      small
      @click="$router.go(-1)"
      class="pl-0"
      text>
      <v-icon>$prev</v-icon>
      Kembali
    </v-btn>


    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div
              style="
                font-size: 24px;
                font-weight: 500;">
              Biodata
            </div>
            <v-simple-table class="py-5" dense>

              <template>

                <tbody>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Name</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0">
                      {{ detailMember.member.fullname ? detailMember.member.fullname : '-' }}
                    </th>
                  </tr>
                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">NIM</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0">
                      {{ detailMember.member?.meta?.nim }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Place</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.member.place_of_birth ? detailMember.member.place_of_birth : '-' }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Date of birth</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0">
                      {{ detailMember.member.date_of_birth | dateOnly }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Test Date</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.end_test | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Test ID</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize">
                      {{ detailMember.order_no ? detailMember.order_no : '-' }}
                    </th>
                  </tr>

                </tbody>

              </template>

            </v-simple-table>
          </v-col>
          <v-col cols="7">
            <div
              style="
                font-size: 24px;
                font-weight: 500;">
              Results
            </div>
            <v-simple-table class="py-5" dense>

              <template>

                <tbody>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Reading</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0" style="flex: 1">
                      {{ detailMember.section.reading.total_point }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submited on {{ detailMember.section.reading.end_test_at | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Structure</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ detailMember.section.structure.total_point }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submited on {{ detailMember.section.structure.end_test_at | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Listening</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0" style="flex: 1">
                      {{ detailMember.section.listening.total_point }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submited on {{ detailMember.section.listening.end_test_at | datetime }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Total Correct Point</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ (detailMember.section.reading.total_point + detailMember.section.structure.total_point + detailMember.section.listening.total_point) }}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">EPT PNK Prediction Score for TOEFL</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ detailMember.score ? detailMember.score : '-'}}
                    </th>
                  </tr>

                  <tr>
                    <th class="text-start px-0" style="font-weight: 400;width: 200px">Description</th>
                    <th class="text-center text-second px-0" style="width: 10px">:</th>
                    <th class="text-start pl-5 pr-0 text-capitalize" style="flex: 1">
                      {{ '-' }}
                    </th>
                  </tr>

                </tbody>

              </template>

            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { audioPlayer } from 'vue-md-player'
export default {
  components: {
    // audioPlayer,
    // videoPlayer
  },
  data: () => ({
    tab: null,
    imagePreview: "",
    list: {},
    show: {
      reading: false,
      structure: false,
      listening: false,
      open_new_tab: {
        reading: false,
        structure: false,
        listening: false
      },
      face: {
        reading: false,
        structure: false,
        listening: false
      },
      speech: {
        reading: false,
        structure: false,
        listening: false
      }
    },
    see_more: {
      reading: false,
      structure: false,
      listening: false,
      open_new_tab: {
        reading: false,
        structure: false,
        listening: false
      },
      face: {
        reading: false,
        structure: false,
        listening: false
      },
      speech: {
        reading: false,
        structure: false,
        listening: false
      }
    },
    detailMember: {},
    selected: [
      {
        section : "reading",
        images : []
      },
      {
        section : "structure",
        images : []
      },
      {
        section : "listening",
        images : []
      }
    ],
    edited: {
      reading: false,
      structure: false,
      listening: false
    },
    dialog: {
      preview: false,
    },
    process: {
      run: false
    },
    notification: {
      state: false,
      text: "",
      color: ""
    },
  }),
  created(){
    if (this.$route.name.includes("capture-detail")) {
      this.$emit("page-changed", 1, { title: "Detail Capture Image", link: "#" });
    }
  },
  mounted() {
    this.initialize()
    this.getMember()
  },
  methods: {
    openPreview(image_url){
      this.dialog.preview = true
      this.imagePreview = image_url
    },
    async save_featured(selected){
      // console.log(this.selected);
      let array_selected = []
      array_selected.push(selected)
      await this.axios.put(`/users/v1/admin/order/media/featured/${this.$route.params.lisenci}`, array_selected)
      .then((res) => res.data)
      .then((res) => {
        if (res.status == 200) {
          this.notification = {
            state: true,
            color: "success",
            text: "Berhasil Menyimpan Primary Photo"
          }
          this.selected[0].images = []
          this.selected[1].images = []
          this.selected[2].images = []
        }else{
          this.notification = {
            state: true,
            color: "red",
            text: "Gagal Menyimpan Primary Photo"
          }
          this.selected[0].images = []
          this.selected[1].images = []
          this.selected[2].images = []
        }
      }).catch(error => {
        let errorData = error.response.data;
        this.notification = {
          state: true,
          color: "red",
          text: errorData.message ? errorData.message : "Terjadi Kesalahan Server"
        }
        this.selected[0].images = []
        this.selected[1].images = []
        this.selected[2].images = []
      })
    },
    async getMember(){
      this.process.run = true;
      // this.$set(this.listData, "list", []);

      this.axios.get(`/users/v1/admin/order/detail-full/${this.$route.params.id}`)
        .then((res) => res.data)
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            this.detailMember = res.data
            this.process.run = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.process.run = false;
          this.dialog.filtered = false;
          this.certifLoading = false;
          let errorData = error.response.data;
          // console.log(errorData);
          this.notification = {
            state: true,
            color: "red",
            text: errorData.message
          }
        });
    },
    async initialize() {
      this.process.run = true

      await this.axios.get(`/users/v1/admin/order/media/${this.$route.params.lisenci}`)
      .then((res) => res.data)
      .then((res) => {

        console.log(res.data);
        // Listening
        res.data.listening.image = res.data.listening.image == null ? [] : res.data.listening.image;
        res.data.listening.violation.open_new_tab.data = res.data.listening.violation.open_new_tab.data == null ? [] : res.data.listening.violation.open_new_tab.data;
        res.data.listening.violation.face.data = res.data.listening.violation.face.data == null ? [] : res.data.listening.violation.face.data;
        res.data.listening.violation.speech.data = res.data.listening.violation.speech.data == null ? [] : res.data.listening.violation.speech.data;
        // Reading
        res.data.reading.image = res.data.reading.image == null ? [] : res.data.reading.image;
        res.data.reading.violation.open_new_tab.data = res.data.reading.violation.open_new_tab.data == null ? [] : res.data.reading.violation.open_new_tab.data;
        res.data.reading.violation.face.data = res.data.reading.violation.face.data == null ? [] : res.data.reading.violation.face.data;
        res.data.reading.violation.speech.data = res.data.reading.violation.speech.data == null ? [] : res.data.reading.violation.speech.data;
        // Structure
        res.data.structure.image = res.data.structure.image == null ? [] : res.data.structure.image;
        res.data.structure.violation.open_new_tab.data = res.data.structure.violation.open_new_tab.data == null ? [] : res.data.structure.violation.open_new_tab.data;
        res.data.structure.violation.face.data = res.data.structure.violation.face.data == null ? [] : res.data.structure.violation.face.data;
        res.data.structure.violation.speech.data = res.data.structure.violation.speech.data == null ? [] : res.data.structure.violation.speech.data;
        this.list = res.data;

      })
    }
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  color: #575757 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.v-alert--text:before {
  background-color: #fefbd8 !important;
}
.v-alert--text {
  background: #fefbd8 !important;
}
.color-warning-cutsom {
  color: #8c7913 !important;
}
</style>